import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { BODY_TEXT_TYPE, HEADER_LEVEL } from "../../utils/commonConst";
import BodyText from "../common/BodyText";
import { Breakpoints, Colors, FontWeights } from "../../styles/styleConstants";
import HeaderText from "../common/HeaderText";
import Image from "../common/Image";

const PRICING_DATA = [
  {
    feature: "Fully personalized annual check-in",
    isFree: true,
    isPremium: true,
  },
  {
    feature: "Premium topics: investing, taxes, real estate",
    isFree: false,
    isPremium: true,
  },
  {
    feature: "Implement your optimizations",
    isFree: false,
    isPremium: true,
  },
  {
    feature: "Regular check-ins through the year",
    isFree: false,
    isPremium: true,
  },
  {
    feature: "Proactive monitoring for new optimizations",
    isFree: false,
    isPremium: true,
  },
  {
    feature: "Expert advice, anytime",
    isFree: false,
    isPremium: true,
  },
];
function PremiumPricingToc(props) {
  const { children, style = undefined } = props || {};
  return (
    <PremiumPricingWrapper>
      <PremiumPricingBody>
        <PremiumPricingHeader>
          <TopicsHeader>
            <HeaderText level={HEADER_LEVEL.LEVEL_4}>
              <TopicsHeaderContent>How we’ll work together</TopicsHeaderContent>
            </HeaderText>
          </TopicsHeader>
          <FreeQualifyHeader>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_5}
              style={{ color: Colors.neutralMedDark }}
            >
              <TopicsHeaderContent>Free</TopicsHeaderContent>
            </HeaderText>
          </FreeQualifyHeader>
          <PremiumQualifyHeader>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_5}
              style={{ color: Colors.coral }}
            >
              <TopicsHeaderContent>Premium</TopicsHeaderContent>
            </HeaderText>
          </PremiumQualifyHeader>
        </PremiumPricingHeader>
        {PRICING_DATA.map((priceObj, indx) => {
          const { feature, isFree, isPremium } = priceObj ?? {};
          return (
            <PricingRowWrapper key={`pri-lst-${indx}`}>
              <PricingRowTopicCell>
                <BodyText type={BODY_TEXT_TYPE.BODY_TEXT_SMALL}>
                  <span style={{ fontWeight: FontWeights.normal }}>
                    {feature}
                  </span>
                </BodyText>
              </PricingRowTopicCell>

              <PricingRowFreeCell>
                {isFree && (
                  // <PricingRowFreeTickImage src="common/tick_grey.png" />
                  <PricingRowFreeTickImage>
                    <Image
                      src="common/tick_grey.png"
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </PricingRowFreeTickImage>
                )}
              </PricingRowFreeCell>

              <PricingRowPremiumCell>
                {isPremium && (
                  // <PricingRowPremiumTickImage src="common/tick_coral.png" />
                  <PricingRowPremiumTickImage>
                    <Image
                      src="common/tick_coral.png"
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </PricingRowPremiumTickImage>
                )}
              </PricingRowPremiumCell>
            </PricingRowWrapper>
          );
        })}
      </PremiumPricingBody>
    </PremiumPricingWrapper>
  );
}

PremiumPricingToc.propTypes = {};

export default PremiumPricingToc;

const PremiumPricingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  background: ${Colors.white};
  border-radius: 4px;

  @media only screen and (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
  }
`;

const PremiumPricingBody = styled.div`
  min-width: 750px;
  margin: 24px;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    min-width: 351px;
  }
`;

const PremiumPricingHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TopicsHeader = styled.div`
  flex-basis: 60%;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    flex-basis: 70%;
  }
`;

const FreeQualifyHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 10%;
`;

const PremiumQualifyHeader = styled.div`
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 20%;
`;

const PricingRowWrapper = styled(PremiumPricingHeader)`
  margin: 16px 0px;
`;

const PricingRowTopicCell = styled(TopicsHeader)``;

const PricingRowFreeCell = styled(FreeQualifyHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PricingRowFreeTickImage = styled.div`
  width: 24px;
  height: 24px;
`;

const PricingRowPremiumCell = styled(PremiumQualifyHeader)``;
const PricingRowPremiumTickImage = styled.div`
  width: 24px;
  height: 24px;
`;

const TopicsHeaderContent = styled.span`
  @media only screen and (max-width: ${Breakpoints.sm}) {
    font-size: 18px;
  }
`;
