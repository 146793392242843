import React from "react";
import styled from "styled-components";
import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import { BODY_TEXT_TYPE } from "../../utils/commonConst";
import BodyText from "../common/BodyText";

export default function Quote(props) {
  return (
    <QuoteWrapper>
      <QuoteBody>
        <QuoteContent>
          "The private banker in your pocket quietly optimizing sh*t while you
          catch up on Stranger Things"
        </QuoteContent>
        <BodyText
          type={BODY_TEXT_TYPE.BODY_TEXT_SMALL}
          style={{ marginTop: "24px" }}
        >
          <span style={{ color: Colors.white }}>- Fintech Insider</span>
        </BodyText>
      </QuoteBody>
    </QuoteWrapper>
  );
}

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${Colors.tertiary};
`;

const QuoteBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 30%;
  @media only screen and (max-width: ${Breakpoints.sm}) {
    margin: 80px 24px;
  }
`;

const QuoteContent = styled.div`
  font-style: normal;
  font-weight: ${FontWeights.bold};
  font-size: ${FontSizes.xl};
  line-height: 34px;
  text-align: center;

  color: ${Colors.white};
`;
