import React from "react";
import Layout from "../layouts/layout";
import Header from "../common/header";
import Footer from "../common/footer";
import styled from "styled-components";
import { AllAccessValueProp } from "../../data/homeData";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import DividerLinesImage from "../../images/home/divider-lines.png";
import { PRICING_TITLE, QUERY_PARAMS } from "../../utils/commonConst";
import { isNonEmpty, parseReqParams } from "../../utils/commonUtil";
import AllAccessHero from "./AllAccessHero";
import HowItWorksV2 from "../home/howItWorksV2";
import CoreProductPricing from "../home/CoreProductPricing";
import PriceCompare from "../home/PriceCompare";
import SideBySideV2 from "../common/sideBySideV2";
import Quote from "../premium/Quote";
import PremiumPricingToc from "../premium/PremiumPricingToc";

export default function AllAccess(props) {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;
  const { Content1, Content2, Content3, Content4, Content5 } =
    AllAccessValueProp;
  return (
    <Layout includeHeader={false} includeFooter={false}>
      {/* {process.env.GATSBY_SHOW_BANNER === "true" && <Banner />} */}
      <HeroWrapper>
        <Header />
        <AllAccessHero signupCode={signupCode} />
      </HeroWrapper>
      {/* <Quote /> */}
      <Spacer />
      <SideBySideV2 content={Content1} flip={true} />
      <SideBySideV2 content={Content2} />
      <SideBySideV2 content={Content3} flip={true} />
      <SideBySideV2 content={Content4} />
      <SideBySideV2 content={Content5} flip={true} />
      <div style={{ paddingBottom: "40px" }} />
      <HowItWorksV2 signupCode={signupCode} />
      <CoreProductPricing signupCode={signupCode} title={PRICING_TITLE} />
      {/* <DividerLinesWrapper>
        <img src={DividerLinesImage} alt="------" />
      </DividerLinesWrapper> */}
      <PriceCompare signupCode={signupCode} />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const DividerLinesWrapper = styled.div`
  margin-bottom: ${Spacing.xxxxl};

  img {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: ${Spacing.xl};
  }
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const Spacer = styled.div`
  padding: 48px 0px;
`;
